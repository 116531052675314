@import '../../styles/customMediaQueries.css';
.inputLabel {
  font-weight: var(--fontWeightSemiBold);
  margin: 20px 0 0 0;
  line-height: 16px;
  font-size: 14px;
}

.error {
  color: red;
  line-height: 22px;
}

.latLngInput {
}

.mapSection {
  padding: 24px;
  border: solid 1px var(--colorGrey100);
  background-color: var(--colorGrey50);
  margin-top: 24px;
  @media (--viewportLarge) {
    margin-top: 32px;
  }
}
& :global(.mapboxgl-map) {
  margin-bottom: 24px;
}
& :global(.mapboxgl-ctrl) {
  display: flex;
  align-items: center;
  box-shadow: none;
  margin-bottom: 24px;
  width: 100%;
  max-width: 100%;
}

& :global(.mapboxgl-ctrl-geocoder--icon-search) {
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}
& :global(.mapboxgl-ctrl-geocoder--input) {
  padding-left: 45px;
  border: solid 1px var(--colorGrey100);
}
.formRow {
  display: flex;
  flex-direction: column;
  @media (--viewportSmall) {
    flex-direction: row;
  }
  & .formFld {
    width: 100%;
    margin-bottom: 24px;
    @media (--viewportSmall) {
      width: calc(100% / 2 - 12px);
    }
    &:not(:last-child) {
      @media (--viewportSmall) {
        margin-right: 24px;
      }
    }
    & > label {
      font-size: 15px;
      color: var(--colorGrey600);
      padding: 0;
      margin-bottom: 8px;
      margin-top: 0;
    }
  }
}
