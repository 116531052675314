@import '../../../styles/customMediaQueries.css';
.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  display: block !important;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: block;
  /* padding-top: var(--topbarHeight); */
  @media (--viewportMedium) {
    /* padding-top: var(--topbarHeightDesktop); */
  }
}
