@import '../../styles/customMediaQueries.css';

.images {
  object-fit: contain;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
  /* width: 296px; */
  height: 200px;

  @media only screen and (min-width: 300px) and (max-width: 375px) {
    /* width: 273px; */
    height: 200px;
    object-fit: cover;
  }

  @media only screen and (min-width: 375px) and (max-width: 425px) {
    /* width: 327px; */
    height: 290px;
    object-fit: cover;
  }

  @media only screen and (min-width: 425px) and (max-width: 768px) {
    /* width: 383px; */
    height: 383px;
    object-fit: cover;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    /* width: 235px; */
    height: 295px;
    object-fit: cover;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1440px) {
    /* width: 211px; */
    height: 283px;
    object-fit: cover;
  }

  @media (min-width: 1440px) {
    /* width: 293px; */
    height: 343px;
    object-fit: cover;
  }
}

.heroImages {
  object-fit: contain;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
  /* width: 296px; */
  height: 800px;

  @media only screen and (min-width: 300px) and (max-width: 375px) {
    /* width: 273px; */
    height: 200px;
    object-fit: cover;
  }

  @media only screen and (min-width: 375px) and (max-width: 425px) {
    /* width: 327px; */
    height: 290px;
    object-fit: cover;
  }

  @media only screen and (min-width: 425px) and (max-width: 768px) {
    /* width: 383px; */
    height: 383px;
    object-fit: cover;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
    height: 360px;
    object-fit: cover;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1440px) {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  @media (min-width: 1440px) {
    /* width: 293px; */
    height: 770px;
    object-fit: cover;
  }
}

.carouselContainer {
  position: relative;
  @media (max-width: 767px) {
    border-radius: 10px;
    overflow: hidden;
  }

  & :global(.slick-dots) {
    bottom: 10px;
  }
  & :global(.slick-dots > li) {
    margin: 0;
  }
  & :global(.slick-dots > li > button) {
    width: 8px;
    height: 8px;
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--colorWhite);
    border: none;
    margin: 0 6px 0 0;
  }

  & :global(.slick-dots > li.slick-active > button) {
    background-color: var(--marketplaceColor);
  }
  & :global(.slick-dots > li > button::before) {
    display: none;
  }
  & :global(.slick-list) {
    position: relative;
    z-index: 0;
  }
  & :global(.slick-arrow) {
    z-index: 1;
    transform: none;
    width: 34px;
    height: 34px;
    transition: var(--transitionStyle);
  }
  & :global(.slick-arrow > svg:hover path) {
    stroke: var(--marketplaceColor);
    transition: var(--transitionStyle);
    strokewidth: 3px;
  }
  & :global(.slick-arrow > svg path) {
    transition: var(--transitionStyle);
  }

  & :global(.slick-arrow > svg) {
    width: 34px;
    height: 34px;
    transition: var(--transitionStyle);
  }
  & :global(.slick-arrow::before) {
    display: none;
  }
  & :global(.slick-arrow.slick-prev) {
    left: 10px;
  }
  & :global(.slick-arrow.slick-next) {
    right: 10px;
  }
  & :global(.slick-slide) {
    & img {
      border-radius: 10px;
    }
  }
}

.sliderButtons {
  position: absolute;
  z-index: 2;
  padding: 0px 14px;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;

  & .iconHolder {
    background-color: #fff;
    border: 1px solid #ebebeb;
    width: 35px;
    height: 35px;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all ease 0.5s;
    cursor: pointer;

    @media (max-width: 325px) {
      width: 22px;
      height: 22px;
    }

    @media (max-width: 375px) {
      width: 24px;
      height: 24px;
    }

    @media (max-width: 425px) {
      width: 28px;
      height: 28px;
    }
    @media (--viewportXXLarge) {
      width: 56px;
      height: 56px;
    }

    &:hover {
      border-color: var(--marketplaceColor);
      cursor: pointer;

      & > svg {
        & > circle {
          background-color: var(--matterColorLight);
        }

        & > path {
          stroke: var(--marketplaceColor);
        }
      }
    }

    &:first-child {
      margin-right: 4px;

      @media (--viewportXXLarge) {
        margin-right: 14px;
      }
    }

    & svg {
      fill: none;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .cardSlider {
    & :global(.slick-list) {
      height: 100% !important;
      & a {
        width: 100%;
        height: 100%;
        position: relative;
        padding-bottom: 100%;
        border-radius: 10px;
        overflow: hidden;
        & > img {
          top: 0;
          left: 0;
          position: absolute;
          max-width: 100%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
