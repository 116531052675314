/* Inline Modifiers */
strong,
em,
u,
s,
code {
  margin: 0 1px;
}

/* Code blocks */
pre {
  background-color: #f6f8fa;
  padding: 10px;
  overflow-x: auto;
  border-radius: 4px;
  margin: 2px 0;
  font-size: 14px;
}

/* Blockquote styling */
blockquote {
  border-left: 3px solid #ccc;
  padding-left: 10px;
  color: #555;
  margin: 2px 0;
  font-style: italic;
}

/* Lists */
ul,
ol {
  padding-left: 18px;
  margin: 2px 0;
}

li {
  margin-bottom: 4px;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 12px 0 8px;
  line-height: 1.3;
}

/* Paragraphs */
p {
  margin: 8px 0;
}
