@import '../../../../../styles/customMediaQueries.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--colorFail);
}

.locationBox {
  margin: 24px 0px;
}

.tabs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin-bottom: 20px;
}

.tab {
  cursor: pointer;
  border: none;
  border-bottom: 2px solid var(--colorGrey100);
  padding: 10px 0;
  font-size: 12px;
  line-height: 120%;
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorGrey400);
  transition: var(--transitionStyle);
  @media (--viewportMedium) {
    font-size: 14px;
  }
  &:hover {
    color: var(--colorGrey900);
    transition: var(--transitionStyle);
    border-bottom: 2px solid var(--colorGrey900);
  }
}
.activeTab {
  padding: 10px 0;
  cursor: pointer;
  border: none;
  border-bottom: 2px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
  font-size: 12px;
  line-height: 120%;
  font-weight: var(--fontWeightSemiBold);
  @media (--viewportMedium) {
    font-size: 14px;
  }
}

.formFields {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.coordinateField {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  margin-bottom: 24px;
  & > select {
    background-color: var(--colorGrey50);
    border-color: var(--colorGrey50);
    line-height: 24px;
    font-size: 14px;
    min-height: auto;
    @media (--viewportMedium) {
      line-height: 32px;
      font-size: 16px;
    }
    &:hover,
    &:focus,
    &:active {
      background-color: var(--colorGrey50);
      border-color: var(--colorGrey50);
    }
  }
}

.textField {
  width: 100%;
  font-size: 14px;
  & > label {
    font-weight: var(--fontWeightRegular);
  }
  & > input {
    background-color: var(--colorGrey50);
    border-color: var(--colorGrey50);
    line-height: 24px;
    font-size: 14px;
    @media (--viewportMedium) {
      line-height: 32px;
      font-size: 16px;
    }
    &:hover,
    &:focus,
    &:active {
      background-color: var(--colorGrey50);
      border-color: var(--colorGrey50);
    }
  }
}
.unit {
  top: -4px;
  position: relative;
  color: var(--colorGrey400);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  @media (--viewportMedium) {
    top: -9px;
  }
}
.directionField {
  width: 160px;
  border: 1px solid var(--colorGrey50);
  border-radius: 4px;
  border: 1px solid var(--colorGrey50);
  font-size: 14px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 20px;
}

.submitButton {
  margin-top: 20px;
  width: 100%;
}
