@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 10px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 0px;
}

.priceValue {
  font-size: 18px;
  font-weight: var(--fontWeightBold);
  color: var(--colorBlack);
  margin: 0;
  padding: 0;
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightRegular);
  color: var(--colorGrey600);
  display: inline-flex;
  align-items: center;

  /* Remove default margins from font */
  margin: 0 0 0 4px;
  padding: 0;
}
.ratingPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .ratings {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > svg {
      width: 14px;
      height: 14px;
      &:not(:last-child) {
        margin-right: 2px;
      }
      & > path {
        fill: var(--colorBlack);
      }
    }
    & .totalReview {
      color: var(--colorGrey600);
      font-size: 14px;
      line-height: 130%;
      margin-left: 4px;
    }
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 16px;
  line-height: 130%;
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorBlack);
  margin: 0 0 6px 0;
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.authorInfo {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightRegular);
  color: var(--colorGrey800);
  font-size: 16px;
  line-height: 140%;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 6px;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.infoOnly {
  background: #0077be;
  color: white;
  padding: 0px 8px;
  border-radius: 6px;
  font-size: 13px;
}

.imageContainer {
  position: relative;
}

.badge {
  position: absolute;
  top: 20px;
  right: 10px;
  background-color: #333333;
  color: #fff;
  padding: 2px 6px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;

  & svg {
    height: 20px;
    width: 20px;
  }
}
