@import '../../../styles/customMediaQueries.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--colorWhite);

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0px 24px 48px 24px;
  max-width: var(--contentMaxWidth);
  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
    padding: 48px 24px;
  }
  & > div {
    width: 100%;
    @media (--viewportLarge) {
      width: calc(100% - 300px);
    }
  }
}

.nav {
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowLight);
  width: 100%;
  justify-content: space-between;

  @media (--viewportLarge) {
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--colorWhite);
    box-shadow: none;
    width: 100%;
    max-width: 300px;
  }
}

.tab {
  @media (--viewportLarge) {
    &::after {
      content: '';
      background-color: var(--colorGrey100);
      width: 1px;
      height: 100%;
      position: absolute;
      z-index: 0;
      top: 0;
      right: 14px;
    }
  }
  & > a {
    &::before {
      display: none;
    }
  }
  &:not(:last-child) {
    @media (--viewportLarge) {
      margin-bottom: 60px;
    }
    & > a {
      &::before {
        display: block;
      }
    }
  }
}

.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    padding-top: 11px;
  }
}

.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.customFieldMultiEnum {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}
