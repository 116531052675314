@import '../../styles/customMediaQueries.css';

.root {
  display: block;

  flex-shrink: 0;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
  & > img {
    max-width: 130px;
    width: 100%;
    @media (--viewportMedium) {
      max-width: 150px;
    }
  }
}
