@import '../../../../styles/customMediaQueries.css';
.availablityCalendar {
  width: 100%;

  @media (--viewportLarge) {
    & :global(.DateRangePicker) {
      width: 100%;
      background-color: var(--colorWhite);
      position: relative;
      height: 100%;
    }
    & :global(.DateRangePicker > div) {
      height: 100%;
    }
    & :global(.DayPickerNavigation_button__verticalDefault) {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      & > svg {
        width: 20px;
        height: 20px;
      }
    }

    & :global(.DateRangePickerInput) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    & :global(.DateRangePicker_picker) {
      position: relative;
      top: 0 !important;
      order: 1;
      /* border: solid 1px var(--colorGrey100);
      border-radius: 10px; */
      background-color: var(--colorWhite);
      /* box-shadow: var(--boxShadow); */
      padding: 10px;
      height: 100%;
    }
    & :global(.CalendarMonthGrid_month__horizontal) {
      width: 100% !important;
      padding: 0px;
      @media (--viewportMedium) {
        max-width: calc(100% / 2 - 13px) !important;
      }

      @media (--viewportLargeWithPaddings) {
        max-width: calc(100% / 2 - 24px) !important;
      }
    }
    & :global(.CalendarMonthGrid_month__horizontal:nth-child(2)) {
      margin-right: 24px;
      @media (--viewportLargeWithPaddings) {
        margin-right: 48px;
      }
    }
    & :global(.CalendarDay__default:hover) {
      background: #34a5e6;
      border: 1px solid #047cc3;
      color: #fff;
    }

    & :global(.CalendarDay__default.CalendarDay__today:hover),
    & :global(.CalendarDay__default.CalendarDay__selected:hover),
    & :global(.CalendarDay__default.CalendarDay__selected_span:hover) {
      background-color: var(--marketplaceColor);
      color: var(--colorWhite);
    }

    & :global(.CalendarMonth) {
      padding: 0 !important;
      border-radius: 10px;
    }
    & :global(.DayPicker) {
      border: none;
      box-shadow: none;
      border-radius: 10px;
      width: 100% !important;
    }
    & :global(.DayPicker > div > div) {
      width: 100% !important;
    }
    & :global(.DayPicker_transitionContainer) {
      width: 100% !important;
      /* height: 431px !important; */
      @media (--viewportMedium) {
        max-width: 982px !important;
        /* height: 573px !important; */
      }
    }
    & :global(.CalendarMonthGrid__horizontal) {
      width: 100% !important;
      left: 0;
      border-radius: 10px;
    }
    & :global(.DayPicker_weekHeaders__horizontal) {
      margin-left: 0;
    }
    & :global(.DayPicker_weekHeaders) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      top: 70px;
    }
    & :global(.DayPicker_weekHeader) {
      width: calc(100% / 2 - 12px) !important;
      left: 0 !important;
      padding: 0 !important;
      /* border-bottom: solid 1px var(--colorGrey100); */
      position: relative;
      top: 0;

      @media (--viewportLargeWithPaddings) {
        width: calc(100% / 2 - 24px) !important;
      }
    }
    & :global(.DayPicker_weekHeader_li) {
      padding: 0px 10px;
      font-weight: var(--fontWeightSemiBold);
    }
    & :global(.DayPicker_weekHeader__vertical) {
      width: 100%;
      left: 0;
    }
    & :global(.CalendarMonth_table) {
      width: 100% !important;
    }
    & :global(.CalendarMonth_table > tbody > tr > td) {
      padding: 4px 10px;
    }
    & :global(.CalendarDay__blocked_out_of_range),
    & :global(.CalendarDay__blocked_out_of_range:active),
    & :global(.CalendarDay__blocked_out_of_range:hover) {
      background-color: #f1f1f1;
      color: var(--colorGrey500);
    }
    & :global(.CalendarMonth_caption) {
      padding-bottom: 43px;
      @media (--viewportMedium) {
        padding-bottom: 60px;
      }
    }
    & :global(.DateInput) {
      position: absolute;
      top: 100%;
      order: 4;
      left: 50%;
      & > input {
        border: none;
        border-radius: 0;
        background-color: var(--colorGrey50);
      }
      & > svg {
        display: none;
      }
      &:first-of-type {
        left: 0;
        order: 2;
      }
    }
    & :global(.DateRangePickerInput_arrow) {
      order: 3;
      width: 50px;
      left: 30%;
      position: absolute;
      top: 100%;
      height: 50px;
      display: none;
      align-items: center;
      justify-content: center;
    }
  }
  & :global(.DateInput),
  & :global(.DateRangePickerInput_arrow) {
    display: none;
  }

  @media (max-width: 1023px) {
    & :global(.DateRangePickerInput) {
      width: 100%;
    }
    & :global(.DateRangePicker) {
      width: 100%;
    }
    & :global(.DateRangePicker_picker) {
      width: 100%;
      position: relative;
      top: 0 !important;

      & :global(.CalendarMonthGrid__vertical) {
        width: 100% !important;
      }
      & :global(.CalendarMonth_table) {
        width: 100%;
      }
      & :global(.DayPickerNavigation_button) {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        & > svg {
          width: 20px;
          height: 20px;
        }
      }
      & :global(.DayPicker_weekHeader) {
        width: 100%;
        margin: 0 !important;
        left: 0;
      }
      & :global(.DayPicker_weekHeader_ul) {
        width: 100%;
      }
      & :global(.DayPicker_weekHeader_li) {
        width: calc(100% / 7) !important;
      }
      & :global(.CalendarDay) {
        width: calc(100% / 7) !important;
        height: 30px !important;
      }
      & :global(.CalendarMonthGrid_month__hideForAnimation + div + div .CalendarMonth_caption) {
        padding-bottom: 10px;
        padding-top: 10px;
      }
      & :global(.CalendarMonth_caption) {
        padding-bottom: 57px;
      }
    }
  }
}

.checkboxes {
}
.priceText {
  font-size: 12px;
  color: #07cb07;
  font-weight: 800;
}
