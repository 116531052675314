@import '../../styles/customMediaQueries.css';

.verifyForm {
  & > h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 130%;
    color: var(--colorGrey900);
    padding: 0;
    margin: 0 0 10px 0;
  }

  & > p {
    margin: 0 0 10px 0;
    padding: 0;
    font-size: 16px;
    line-height: 130%;
    font-weight: var(--fontWeightRegular);
  }
}

.verificationWrapper {
  margin: 0;

  & > p {
    margin: 0 0 10px 0;
    padding: 0;
    font-size: 16px;
    font-weight: var(--fontWeightRegular);
  }
}

.phoneNumberWrapper {
  margin: 16px 0 0;
}

.verificationCodeWrapper {
  margin-top: 24px;
}

.otpContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;

  & > input {
    height: auto;
    text-align: center;
    width: 100% !important;
    padding: 0;

    @media (--viewportMedium) {
      min-height: 50px;
    }
  }
}

.btnWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 24px;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
}
.errorWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 24px 0;
  & svg {
    width: 24px;
    height: 24px;
    stroke: var(--colorFail);
  }
  & > p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: var(--colorFail);
  }
}
