@import '../../../styles/customMediaQueries.css';

.panel {
  flex-grow: 1;
  background-color: var(--colorWhite);
  padding-left: 0px;
  @media (--viewportLarge) {
    padding-left: 68px;
  }

  & > h1 {
    margin-top: 0;
    line-height: 100%;
    padding: 0;
    font-size: 20px;
    line-height: 130%;
    color: var(--colorGrey700);
    margin-bottom: 24px;
    @media (--viewportLarge) {
      font-size: 24px;
      margin-bottom: 32px;
    }
  }
}

label {
  font-size: 15px;
  color: var(--colorGrey600);
  padding: 0;
  margin-bottom: 8px;
  margin-top: 0;
}
