@import '../../styles/customMediaQueries.css';
.contentWidth {
  position: relative;
  z-index: 0;
  max-width: 1290px;
  width: 100%;
  margin: 0px auto;
  padding: 40px 24px 20px;
  @media (--viewportLarge) {
    padding: 60px 24px 20px;
  }
}
.footer {
  border-top: solid 1px var(--colorGrey100);
  background-color: var(--colorWhite);
  & .footerTop {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 30px;
    flex-direction: column;
    @media (--viewportSmall) {
      flex-direction: row;
    }
    & .linkBlock {
      width: 100%;

      @media (--viewportSmall) {
        width: calc(100% / 4);
      }
      &:not(:last-child) {
        margin-bottom: 20px;
        @media (--viewportSmall) {
          margin-bottom: 0;
        }
      }
      & > h2 {
        font-weight: var(--fontWeightHeavy);
        font-size: 13px;
        text-transform: uppercase;
        line-height: 130%;
        margin: 0 0 10px 0;
        padding: 0;
        color: var(--colorGrey700);
        @media (--viewportLarge) {
          font-size: 14px;
          margin: 0 0 14px 0;
        }
      }
      & > a {
        color: var(--colorGrey600);
        font-weight: var(--fontWeightRegular);
        font-size: 14px;
        line-height: 140%;
        display: inline-block;
        width: 100%;
        text-decoration: none;
        transition: var(--transitionStyle);
        @media (--viewportLarge) {
          font-size: 15px;
        }
        &:not(:last-child) {
          margin-bottom: 5px;
          @media (--viewportLarge) {
            margin-bottom: 10px;
          }
        }
        &:hover {
          transition: var(--transitionStyle);
          color: var(--marketplaceColor);
        }
      }
      & .socialLinks {
        display: flex;
        align-items: center;
        margin-top: 10px;
        float: left;
        width: 100%;
        & > * {
          border-radius: 100px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: var(--transitionStyle);

          &:not(:last-child) {
            margin-right: 10px;
            @media (--viewportLarge) {
              margin-right: 15px;
            }
          }
          & > svg {
            & path {
              transition: var(--transitionStyle);
            }
          }
          &:hover {
            border-color: var(--marketplaceColor);
            transition: var(--transitionStyle);
            & > svg {
              & path {
                fill: var(--marketplaceColor);
                transition: var(--transitionStyle);
              }
            }
          }
        }
      }
    }
  }
  & .footerBottom {
    border-top: solid 1px var(--colorGrey100);
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media (--viewportSmall) {
      align-items: center;
      flex-direction: row;
    }
    & > a {
      width: 100%;
      @media (--viewportSmall) {
        width: auto;
      }
      & > img {
        max-width: 100px;
      }
    }
    & .rightLinks {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      @media (--viewportSmall) {
        flex-wrap: nowrap;
        justify-content: flex-end;
      }
      & > * {
        color: var(--colorGrey600);
        font-weight: var(--fontWeightRegular);
        font-size: 14px;
        line-height: 140%;
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        transition: var(--transitionStyle);
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
      & > p {
        width: 100%;
        margin: 5px 0px !important;
        @media (--viewportSmall) {
          width: auto;
          margin-right: 20px !important;
        }
      }
      & > a {
        text-decoration: underline;
        position: relative;
        display: none;
        @media (--viewportMedium) {
          display: inline-flex;
        }

        &:hover {
          transition: var(--transitionStyle);
          color: var(--marketplaceColor);
        }
        &::after {
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100px;
          display: none;
          background-color: var(--colorGrey200);
          margin: 0 0 0 10px;
        }
        &:not(:last-of-type) {
          &::after {
            display: inline-block;
          }
        }
      }
    }
  }
  & :global(.accordion__button:before) {
    transform: rotate(45deg);
  }
  & :global(.accordion__button[aria-expanded='true']:before) {
    transform: rotate(225deg);
  }
  & :global(.accordion__button[aria-selected='true']:before) {
    transform: rotate(225deg);
  }
}
.mobileFooter {
  background-color: var(--colorWhite);
  & a {
    color: var(--colorGrey600);
    font-weight: var(--fontWeightRegular);
    font-size: 14px;
    line-height: 140%;
    display: inline-block;
    width: 100%;
    text-decoration: none;
    transition: var(--transitionStyle);
    padding: 5px 0;
    @media (--viewportLarge) {
      font-size: 15px;
    }
    &:not(:last-child) {
      margin-bottom: 5px;
      @media (--viewportLarge) {
        margin-bottom: 10px;
      }
    }
    &:hover {
      transition: var(--transitionStyle);
      color: var(--marketplaceColor);
    }
  }
  & .linkBlock {
    padding: 20px;
    float: left;
    width: 100%;
    background-color: var(--colorWhite);
    & > h2 {
      font-weight: var(--fontWeightHeavy);
      font-size: 13px;
      text-transform: uppercase;
      line-height: 130%;
      margin: 0 0 10px 0;
      padding: 0;
      color: var(--colorGrey700);
      @media (--viewportLarge) {
        font-size: 14px;
        margin: 0 0 14px 0;
      }
    }
    & > a {
      color: var(--colorGrey600);
      font-weight: var(--fontWeightRegular);
      font-size: 14px;
      line-height: 140%;
      display: inline-block;
      width: 100%;
      text-decoration: none;
      transition: var(--transitionStyle);
      @media (--viewportLarge) {
        font-size: 15px;
      }

      &:hover {
        transition: var(--transitionStyle);
        color: var(--marketplaceColor);
      }
    }
    & .socialLinks {
      display: flex;
      align-items: center;
      margin-top: 10px;
      float: left;
      width: 100%;
      & > * {
        border: solid 1px var(--colorGrey600);

        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: var(--transitionStyle);
        margin-bottom: 0;

        &:not(:last-child) {
          margin-right: 10px;
          @media (--viewportLarge) {
            margin-right: 15px;
          }
        }
        & > svg {
          & path {
            transition: var(--transitionStyle);
          }
        }
        &:hover {
          border-color: var(--marketplaceColor);
          transition: var(--transitionStyle);
          & > svg {
            & path {
              fill: var(--marketplaceColor);
              transition: var(--transitionStyle);
            }
          }
        }
      }
    }
  }
  & .footerBottom {
    background-color: var(--colorWhite);
    border-top: solid 1px var(--colorGrey100);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    float: left;
    width: 100%;

    & > a {
      padding: 0;
      & > img {
        max-width: 100px;
      }
    }
    & .rightLinks {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      & > * {
        color: var(--colorGrey600);
        font-weight: var(--fontWeightRegular);
        font-size: 14px;
        line-height: 140%;
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        width: auto;
        transition: var(--transitionStyle);
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
      & > p {
        margin-right: 20px !important;
      }
      & > a {
        text-decoration: underline;
        position: relative;
        &:hover {
          transition: var(--transitionStyle);
          color: var(--marketplaceColor);
        }
        &::after {
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100px;
          display: none;
          background-color: var(--colorGrey200);
          margin: 0 0 0 10px;
        }
        &:not(:last-of-type) {
          &::after {
            display: inline-block;
          }
        }
      }
    }
  }
}
