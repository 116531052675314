@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--colorFail);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.listingTypeSelect {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.selectedLabel {
  composes: label from global;
}
.selectedValue {
  margin-top: 0;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding: 2px 0 6px 0;
  }
}

.unitTypeHidden {
  display: none;
}

.submitButton {
  margin-top: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 48px;
  }
}

.formRow {
  display: flex;
  flex-direction: column;
  @media (--viewportSmall) {
    flex-direction: row;
  }
  &.fullWidth {
    & .formFld {
      width: 100%;
      margin-right: 0;
    }
  }
  & .formFld {
    width: 100%;
    margin-bottom: 24px;
    @media (--viewportSmall) {
      width: calc(100% / 2 - 12px);
    }
    &:not(:last-child) {
      @media (--viewportSmall) {
        margin-right: 24px;
      }
    }
    & > label {
      font-size: 15px;
      color: var(--colorGrey600);
      padding: 0;
      margin-bottom: 8px;
      margin-top: 0;
    }
  }
}

label {
  font-size: 15px;
  color: var(--colorGrey600);
  padding: 0;
  margin-bottom: 8px;
  margin-top: 0;
}
