@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  /* display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  } */
}

.reviewItem {
}
.reviewerSec {
  display: flex;
  align-items: center;
  & .avatar {
    width: 48px;
    height: 48px;
  }
  & .reviewerHead {
    display: flex;
    flex-direction: column;
    width: calc(100% - 48px);
    padding-left: 14px;
    & .userName {
      margin-bottom: 5px;
      font-weight: var(--fontWeightSemiBold);
      font-size: 16px;
      color: var(--colorBlack);
      line-height: 110%;
      padding: 0;
    }
    & > p {
      font-size: 14px;
      line-height: 130%;
      color: var(--colorGrey800);
      font-weight: var(--fontWeightRegular);
      margin: 0;
      padding: 0;
    }
    & .desktopReviewRatingWrapper {
      /* For aligning rating starts in the middle
     * of a paragraph line */
      display: none;

      @media (--viewportMedium) {
        display: inline-flex;
      }
      & .desktopReviewRating {
        /* For aligning rating starts in the middle
       * of a paragraph line */
        display: inline-flex;
        white-space: nowrap;
      }
    }
  }
}
.reviewContentSec {
  margin-top: 10px;
}
.review {
  display: flex;
  flex-direction: column;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;
}

.reviewContent {
  margin: 10px 0;
  padding: 0;
  white-space: pre-line;
  font-weight: var(--fontWeightRegular);
  line-height: 150%;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  &.modalReviewContent {
    display: block;
  }
}

.reviewInfo {
  composes: h5 from global;
  margin-bottom: 10px;
  margin-top: 0px;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 6px;
  & > span {
    color: var(--colorBlack);
    font-weight: var(--fontWeightRegular);
    font-size: 12px;
  }
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}

.imagesReviews {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin-top: 24px;
  @media (--viewportSmall) {
    grid-template-columns: repeat(8, 1fr);
  }
  @media (--viewportMedium) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (--viewportLarge) {
    grid-template-columns: repeat(8, 1fr);
  }
  & .imgThumb {
    position: relative;
    padding-bottom: 100%;
    border-radius: 10px;
    overflow: hidden;
    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: 100%;
    }
  }
}

.showMoreLink {
  color: var(--colorBlack);
  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  text-decoration: underline;
  transition: var(--transitionStyle);
  cursor: pointer;
  &:hover {
    transition: var(--transitionStyle);
    color: var(--marketplaceColor);
  }
}
