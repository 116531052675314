@import '../../styles/customMediaQueries.css';

.root {
  composes: h4 from global;
  margin-top: 6px !important;
  margin-bottom: 0;
  color: var(--colorFail);
  overflow: hidden;
  font-size: 13px;
  text-align: left;
  line-height: 120%;
}
