@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  composes: textSmall from global;

  color: var(--colorGrey700);
  padding-bottom: 10px;
  padding-top: 10px;
  height: 100%;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);
  background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fillRule='evenodd'/></svg>");
  background-position: calc(var(--TabNav_linkWidth) + 4px) center; /* SelectedLink's width (200px) + border thickness (4px) = 204px */

  &:hover {
    text-decoration: none;
    color: var(--colorBlack);
  }

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    font-size: 20px;
    line-height: 24px;

    height: unset;
    width: var(--TabNav_linkWidth);
    border-bottom-width: 0px;
    margin-top: 0;
    margin-bottom: 16px;

    padding-top: 5px; /* Drop the text to adjust it to correct baseline */
    padding-bottom: 3px;

    &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fillRule='evenodd'/></svg>");
      background-position: right center;
    }
  }
}

.selectedLink {
  border-bottom-color: var(--colorBlack);
  color: var(--colorBlack);

  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
    background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fillRule='evenodd'/></svg>");
    background-position: right center;

    &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fillRule='evenodd'/></svg>");
      background-position: right center;
    }
  }
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}

.editListingTabs {
  text-align: right;
  counter-reset: section;
  position: relative;
  background-color: var(--colorWhite);
  margin-bottom: 24px;
  overflow-x: auto;
  @media (--viewportLarge) {
    margin-bottom: 0px;
    overflow-x: initial;
  }

  & .link {
    padding: 20px 20px 20px 35px;
    margin: 0;
    font-size: 14px;
    font-weight: var(--fontWeightMedium);
    color: var(--colorGrey300);
    position: relative;
    background-image: none;
    border-bottom: none;
    @media (--viewportLarge) {
      padding: 0 50px 0 0;
      font-size: 16px;
    }
    &:hover {
      background-image: none;
    }
    @media (--viewportLarge) {
      &::before {
        content: '';
        background-color: var(--colorGrey200);
        width: 5px;
        height: 5px;
        border-radius: 10px;
        position: absolute;
        top: 50px;
        right: 12px;
        z-index: 2;
      }
    }
    &::after {
      width: 24px;
      height: 24px;
      border-radius: 100px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: var(--colorGrey300);
      border: solid 1px var(--colorGrey100);
      position: absolute;
      counter-increment: section;
      content: counter(section);
      left: 0;
      right: auto;
      top: 50%;
      transform: translateY(-50%);
      background-color: var(--colorWhite);
      z-index: 2;

      @media (--viewportLarge) {
        width: 30px;
        height: 30px;
        right: 0;
        font-size: 16px;
        left: auto;
      }
    }
    &.selectedLink {
      background-image: none;
      color: var(--colorBlack);
      border-bottom: none;
      &::after {
        color: var(--colorWhite);
        background-color: var(--marketplaceColor);
        border: solid 1px var(--marketplaceColor);
      }
      &:hover {
        background-image: none;
      }
    }
    &.completedLink {
      background-image: none;
      color: var(--marketplaceColor);
      border-bottom: none;
      &::before {
        width: 24px;
        height: 24px;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: var(--marketplaceColor);
        border: solid 1px var(--marketplaceColor);
        position: absolute;
        right: auto;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: var(--colorWhite);
        z-index: 3;
        content: '\2713';
        @media (--viewportLarge) {
          right: 0;
          left: auto;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
